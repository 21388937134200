export const config = {
    backend: {
        USER_SETTINGS_SERVICE: process.env.REACT_APP_SERVICEURIS_USERSETTINGS_SERVICE,
        DEVICE_STATE_SERVICE: process.env.REACT_APP_SERVICEURIS_DEVICE_STATE_SERVICE,
    },
    frontend: {
        HEATMAP: process.env.REACT_APP_FRONTENDURIS_HEATMAP,
    },
    homeRoute: process.env.REACT_APP_HOME_ROUTE,
    id: process.env.REACT_APP_ID,
    login: {
        authority: process.env.REACT_APP_LOGIN_AUTHORITY,
        clientId: process.env.REACT_APP_LOGIN_CLIENTID,
        mockAuthorization: getBoolEnvValue('REACT_APP_LOGIN_MOCK_AUTHORIZATION'),
        mockLocale: process.env.REACT_APP_LOGIN_MOCK_LOCALE,
        preventRedirect: getBoolEnvValue('REACT_APP_LOGIN_PREVENT_REDIRECT'),
        redirectUri: process.env.REACT_APP_LOGIN_REDIRECT_URI,
        silentRedirectUri: process.env.REACT_APP_LOGIN_SILENT_REDIRECT_URI,
    },
    logoutUri: process.env.REACT_APP_LOGOUT_URI,
};

function getBoolEnvValue(envValue: string): boolean {
    return typeof process.env[envValue] !== 'undefined' && process.env[envValue] === 'true';
}
