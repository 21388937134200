import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'rio-uikit';

export default class IdentifierSelectComponent extends PureComponent {
    render() {
        const { identifierOptions, changeIdentifierSelection } = this.props;

        return <Select options={identifierOptions} onChange={changeIdentifierSelection} />;
    }
}

IdentifierSelectComponent.propTypes = {
    identifierOptions: PropTypes.array,
    changeIdentifierSelection: PropTypes.func,
};
