import { createHashHistory } from 'history';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { rootReducer } from './reducers';
import { activeSagas, setupSagas } from './saga';

const composeEnhancers = composeWithDevTools({
});

// eslint-disable-next-line no-alert
const getUserConfirmation = (message, callback) => callback(window.confirm(message));
const sagaMiddleware = setupSagas();

const history = createHashHistory({
    getUserConfirmation,
    hashType: 'noslash',
});

const middleware = [sagaMiddleware];
const createStoreWithMiddleware = composeEnhancers(
    applyMiddleware(...middleware)
)(createStore);

const store = createStoreWithMiddleware(rootReducer);
activeSagas(sagaMiddleware);

export { history, store };
