import { combineReducers } from 'redux';

import configReducer from '../reducers/configReducer';
import loginReducer from '../login/reducer';
import tokenHandlingReducer from '../tokenHandling/reducer';
import devicesRestReducer from '../reducers/devices/devicesRestReducer';
import searchComponentReducer from '../reducers/search/searchComponentStateReducer';
import devicesTableReducer from '../reducers/devices/devicesTableReducer';

export const rootReducer = combineReducers({
    config: configReducer,
    login: loginReducer,
    device: devicesRestReducer,
    devicesTableState: devicesTableReducer,
    searchState: searchComponentReducer,
    tokenHandling: tokenHandlingReducer,
});
