/**
 * device actions
 */

export const FETCH_DEVICE = 'FETCH_DEVICE';
export function fetchDevice(identifierSelection, identifierString, pagination, filter) {
    return {
        type: FETCH_DEVICE,
        payload: {
            identifierSelection: identifierSelection,
            identifierString: identifierString,
            pagination: pagination,
            filter: filter,
        },
    };
}

export const SUCCESSFUL_FETCH_DEVICE = 'SUCCESSFUL_FETCH_DEVICE';
export function successfulFetchDevices(response, reset) {
    return {
        type: SUCCESSFUL_FETCH_DEVICE,
        payload: {
            response: response,
            reset: reset,
        },
    };
}

export const SAVE_DEVICES_TABLE_SETTINGS = 'SAVE_DEVICES_TABLE_SETTINGS';
export function handleSaveDevicesTableSettings(value) {
    return {
        type: SAVE_DEVICES_TABLE_SETTINGS,
        payload: {
            changeSet: value,
        },
    };
}

export const FETCH_CSV_DEVICES_ACTION = 'FETCH_CSV_DEVICES_ACTION';
export function fetchCSVDevicesAction() {
    return {
        type: FETCH_CSV_DEVICES_ACTION,
    };
}

export const FETCH_CSV_DEVICES_SUCCESS_ACTION = 'FETCH_CSV_DEVICES_SUCCESS_ACTION';
export function fetchCSVDevicesSuccessAction(devices) {
    return {
        type: FETCH_CSV_DEVICES_SUCCESS_ACTION,
        payload: devices,
    };
}

export const FETCH_CSV_DEVICES_PENDING_ACTION = 'FETCH_CSV_DEVICES_PENDING_ACTION';
export function fetchCSVDevicesPendingAction(isPending) {
    return {
        type: FETCH_CSV_DEVICES_PENDING_ACTION,
        payload: {
            isPending,
        },
    };
}

export const CLEAR_CSV_DEVICES_DATA_ACTION = 'CLEAR_CSV_DEVICES_DATA_ACTION';
export function clearCSVDevicesDataAction() {
    return {
        type: CLEAR_CSV_DEVICES_DATA_ACTION,
    };
}

export const SAVE_CSV_EXPORT_SETTINGS = 'SAVE_CSV_EXPORT_SETTINGS';
export function saveCSVExportSettingsAction(changeSet) {
    return {
        type: SAVE_CSV_EXPORT_SETTINGS,
        payload: {
            changeSet,
        },
    };
}
