import createSagaMiddleware from 'redux-saga';
import { fetchDevicesSaga, fetchAllDevicesSaga } from '../sagas/devices/devicesSaga';
import { showErrorMessagesSaga, showInfoMessagesSaga } from '../sagas/notificationsSaga';

const combinedSagas = [
    fetchDevicesSaga,
    fetchAllDevicesSaga,
    showErrorMessagesSaga,
    showInfoMessagesSaga,
];

export function setupSagas() {
    return createSagaMiddleware();
}

export function activeSagas(sagaMiddleware) {
    combinedSagas.forEach((saga) => {
        sagaMiddleware.run(saga);
    });
}
