/**
 * notification handling
 */

export const SHOW_ERROR_MESSAGE = 'SHOW_ERROR_MESSAGE';
export const SHOW_INFO_MESSAGE = 'SHOW_INFO_MESSAGE';

export function showErrorMessage(error) {
    return {
        type: SHOW_ERROR_MESSAGE,
        error,
    };
}

export function showInfoMessage(info) {
    return {
        type: SHOW_INFO_MESSAGE,
        info,
    };
}
