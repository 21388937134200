import React from 'react';
import { SAVE_DEVICES_TABLE_SETTINGS } from '../../actions/devices/devicesActions';
import { FormattedMessage } from 'react-intl';
import { formatDate, formatNumber, formatString, formatCoordinates, formatVin } from '../../common/util';

function handleSaveDevicesTableSettings(state, action) {
    const changeSet = action.payload.changeSet;
    const tableHeader = state.tableHeader.map(a => Object.assign({}, a));

    if (changeSet.length === 0) {
        return state;
    }

    changeSet.map(set => {
        const headerFound = tableHeader.find(header => {
            return header.dataField === set.dataField;
        });

        if (headerFound !== undefined) {
            return headerFound.hidden = !set.checked;
        }
        return false;
    });

    return {
        ...state,
        tableHeader,
    };
}

const defaultState = {
    tableHeader: [
        {
            dataField: 'box_serial_number',
            width: '150',
            default: true,
            dataSort: true,
            hidden: false,
            dataFormat: formatString,
            text: <FormattedMessage id='search.identifier.serialNumber' />,
        },
        {
            dataField: 'vin',
            width: '150',
            default: true,
            dataSort: true,
            hidden: false,
            dataFormat: formatVin,
            text: <FormattedMessage id='search.identifier.vin' />,
        },
        {
            dataField: 'vehicle_variant',
            width: '150',
            default: false,
            dataSort: true,
            hidden: false,
            dataFormat: formatString,
            text: <FormattedMessage id='search.devices.table.vehicleVariant' />,
        },
        {
            dataField: 'software_version',
            width: '150',
            default: false,
            dataSort: true,
            hidden: false,
            dataFormat: formatString,
            text: <FormattedMessage id='search.devices.table.swVersion' />,
        },
        {
            dataField: 'hardware_version',
            width: '150',
            default: false,
            dataSort: true,
            hidden: true,
            dataFormat: formatString,
            text: <FormattedMessage id='search.devices.table.hwVersion' />,
        },
        {
            dataField: 'hardware_variant',
            width: '150',
            default: false,
            dataSort: true,
            hidden: false,
            dataFormat: formatString,
            text: <FormattedMessage id='search.devices.table.hwVariant' />,
        },
        {
            dataField: 'last_mileage',
            width: '150',
            default: false,
            dataSort: true,
            hidden: false,
            dataFormat: formatNumber,
            text: <FormattedMessage id='search.devices.table.mileage' />,
        },
        {
            dataField: 'last_mileage_date_time',
            width: '200',
            default: false,
            dataSort: true,
            hidden: false,
            dataFormat: formatDate,
            text: <FormattedMessage id='search.devices.table.mileageTimestamp' />,
        },
        {
            dataField: 'last_online_date_time',
            width: '200',
            default: false,
            dataSort: true,
            hidden: false,
            dataFormat: formatDate,
            text: <FormattedMessage id='search.devices.table.onlinestate' />,
        },
        {
            dataField: 'builtin_position',
            width: '200',
            default: false,
            dataSort: true,
            hidden: false,
            dataFormat: formatCoordinates,
            text: <FormattedMessage id='search.devices.table.builtinPosition' />,
        },
        {
            dataField: 'builtin_date_time',
            width: '200',
            default: false,
            dataSort: true,
            hidden: true,
            dataFormat: formatDate,
            text: <FormattedMessage id='search.devices.table.builtinDateTime' />,
        },
        {
            dataField: 'position_date_time',
            width: '200',
            default: false,
            dataSort: true,
            hidden: true,
            dataFormat: formatDate,
            text: <FormattedMessage id='search.devices.table.positionDateTime' />,
        },
        {
            dataField: 'hardware_region',
            width: '150',
            default: false,
            dataSort: true,
            hidden: true,
            dataFormat: formatString,
            text: <FormattedMessage id='search.devices.table.hwRegion' />,
        },
        {
            dataField: 'vehicle_type',
            width: '150',
            default: false,
            dataSort: true,
            hidden: true,
            dataFormat: formatString,
            text: <FormattedMessage id='search.devices.table.vehicleType' />,
        },
        {
            dataField: 'gateway',
            width: '150',
            default: false,
            dataSort: true,
            hidden: true,
            dataFormat: formatString,
            text: <FormattedMessage id='search.devices.table.gateway' />,
        },
        {
            dataField: 'tco_supplier',
            width: '200',
            default: false,
            dataSort: true,
            hidden: true,
            dataFormat: formatString,
            text: <FormattedMessage id='search.devices.table.tcoSupplier' />,
        },
        {
            dataField: 'tco_variant',
            width: '150',
            default: false,
            dataSort: true,
            hidden: true,
            dataFormat: formatString,
            text: <FormattedMessage id='search.devices.table.tcoVariant' />,
        },
        {
            dataField: 'tco_hardware_number',
            width: '150',
            default: false,
            dataSort: true,
            hidden: true,
            dataFormat: formatString,
            text: <FormattedMessage id='search.devices.table.tcoHwNumber' />,
        },
        {
            dataField: 'tco_software_number',
            width: '150',
            default: false,
            dataSort: true,
            hidden: true,
            dataFormat: formatString,
            text: <FormattedMessage id='search.devices.table.tcoSwNumber' />,
        },
    ],
};

export default function devicesTableReducer(state = defaultState, action = {}) {
    switch (action.type) {
        case SAVE_DEVICES_TABLE_SETTINGS:
            return handleSaveDevicesTableSettings(state, action);
        default:
            return state;
    }
}

export function getDevicesTableHeader(state) {
    return state.devicesTableState.tableHeader;
}
