/*eslint no-else-return: 0*/
/*eslint max-depth: ['error', 5]*/

import React from 'react';
import { select, put, call, takeLatest } from 'redux-saga/effects';
import {
    FETCH_CSV_DEVICES_ACTION,
    FETCH_DEVICE,
    successfulFetchDevices,
    fetchCSVDevicesPendingAction,
    fetchCSVDevicesSuccessAction,
} from '../../actions/devices/devicesActions';
import { handleIsSearching } from '../../../src/actions/search/searchComponentActions';
import { showErrorMessage } from '../../actions/notificationsActions';
import { get } from '../requestUtil';
import { isError, isNotFoundError, getResponseErrorMsg } from '../errorUtil';
import { getBackendServices } from '../../reducers/configReducer';
import { validateSerialNumber, validateVin, isBlank } from '../../common/util';
import { searchIdentifier } from '../../common/constants/searchIdentifier';
import { FormattedMessage } from 'react-intl';
import queryString from 'query-string';
import { getAllDevices } from '../../services/deviceServices';
import { getOr } from 'lodash/fp';

/*
 * Custom exception used to throw exception in case of nested http request fails
 */
export function FetchException(error) {
    this.error = error;
}

/**
 * Builds the request url for backend deviceState service.
 * @param  {Object}    searchObjectives   object holding search parameters, e.g. serials, filters, pagination params
 * @return {String}                       url to REST endpoint with query parameters
 */
export function* buildRequestUrlDeviceStateService(searchObjectives) {
    const backend = yield select(getBackendServices);
    const filter = searchObjectives.filter;

    let serviceUrlWithParam = `${backend.DEVICE_STATE_SERVICE}/devices`;
    const queryParamsStr = queryString.stringify({
        [searchObjectives.searchIdentifier]: searchObjectives.searchIdString,
        limit: searchObjectives.limit,
        cursor: searchObjectives.next,
        ...filter,
    });

    if (!isBlank(queryParamsStr)) {
        serviceUrlWithParam += `?${queryParamsStr}`;
    }

    return yield serviceUrlWithParam;
}

export function* validateInput(searchState) {
    let identifier;

    if (searchState.identifierSelection === searchIdentifier.SEARCH_SERIAL) {
        if (
            !validateSerialNumber(
                searchState.identifierString,
                <FormattedMessage id='search.error.serialNumber' />,
                true
            )
        ) {
            return yield;
        }
        identifier = searchIdentifier.SEARCH_SERIAL;
    } else if (searchState.identifierSelection === searchIdentifier.SEARCH_VIN) {
        if (!validateVin(searchState.identifierString, <FormattedMessage id='search.error.vin' />, true)) {
            return yield;
        }
        identifier = searchIdentifier.SEARCH_VIN;
    }

    const searchObjectives = {
        searchIdString: !isBlank(searchState.identifierString) ? searchState.identifierString : undefined,
        searchIdentifier: identifier,
        next: searchState.pagination.nextId,
        limit: searchState.pagination.limit,
        filter: searchState.filter,
        reset: !searchState.pagination.nextId,
    };

    return yield searchObjectives;
}

export function* showErrorFromException(exception) {
    let msg;
    if (exception instanceof FetchException) {
        const error = exception.error;
        if (isError(error)) {
            msg = getResponseErrorMsg(error);
        } else {
            msg = <FormattedMessage id='search.error.service.not.reachable' />;
        }
    } else {
        msg = exception.message;
    }

    yield put(showErrorMessage(msg));
    yield put(handleIsSearching(false));
}

export function* doFetchDeviceState(searchObjectives) {
    const requestUrl = yield call(buildRequestUrlDeviceStateService, searchObjectives);
    const response = yield call(get, requestUrl);

    if (!response.error || isNotFoundError(response.error)) {
        return yield response;
    } else {
        throw new FetchException(response.error);
    }
}

/**
 * Performs the backend call.
 * @param  {Object}    action   action for fetching devices
 * @return {void}
 */
export function* doFetchDevices(action) {
    try {
        const returnObject = {
            devices: [],
            nextId: undefined,
        };
        const searchState = action.payload;
        const searchObjectives = yield call(validateInput, searchState);

        if (searchObjectives) {
            yield put(handleIsSearching(true));

            const deviceStateResponse = yield call(doFetchDeviceState, searchObjectives);
            if (deviceStateResponse.error) {
                yield call(showErrorFromException, new FetchException(deviceStateResponse.error));
                return;
            }
            const deviceStates = deviceStateResponse.device_states;
            if (deviceStates && deviceStates.length) {
                returnObject.devices = returnObject.devices.concat(deviceStates);
                returnObject.nextId = getOr(undefined, 'paging.cursor_next', deviceStateResponse);
            }
            yield put(handleIsSearching(false));
            yield put(successfulFetchDevices(returnObject, searchObjectives.reset));
        }
    } catch (e) {
        yield call(showErrorFromException, e);
    }
}

export function* doFetchAllDevices() {
    yield put(fetchCSVDevicesPendingAction(true));
    const backends = yield select(getBackendServices);

    try {
        let nextId = '';
        let devices = [];
        do {
            const deviceStateResponse = yield call(getAllDevices, backends, nextId);
            if (deviceStateResponse.error) {
                throw new FetchException(deviceStateResponse.error);
            }
            devices = devices.concat(deviceStateResponse.device_states);
            nextId = deviceStateResponse.paging ? deviceStateResponse.paging.cursor_next : undefined;
        } while (nextId);

        yield put(fetchCSVDevicesSuccessAction(devices));
    } catch (e) {
        yield call(showErrorFromException, e);
    } finally {
        yield put(fetchCSVDevicesPendingAction(false));
    }
}

/**
 * Initiates a fetch of devices.
 * @return {void}
 */
export function* fetchDevicesSaga() {
    yield takeLatest(FETCH_DEVICE, doFetchDevices);
}

/**
 * Initiates a fetch of all devices (e.g. for CSV download).
 * @return {void}
 */
export function* fetchAllDevicesSaga() {
    yield takeLatest(FETCH_CSV_DEVICES_ACTION, doFetchAllDevices);
}
