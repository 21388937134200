import React from 'react';
import { FormattedMessage, IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import { NavLink, Route, Switch, Redirect } from 'react-router-dom';

import { DefaultAccountMenu } from 'rio-accountmenu';
import { SessionExpiredDialog } from 'rio-session-expired-info';
import { ApplicationHeader, NotificationsContainer, ApplicationLayout } from 'rio-uikit';

import DeviceStatePackageJson from '../../package.json';
import { hideSessionExpiredDialog } from '../actions/common/commonActions';
import { routes } from '../common/constants/routes';
import { getSessionExpiredAcknowledged } from '../components/selectors';
import SearchComponent from '../components/search/SearchComponent';
import { config } from '../config';
import languageData, { DEFAULT_LANGUAGE } from '../lang/languageData';
import { isUserSessionExpired } from '../login/selectors';
import { getAccessToken, getIdToken } from '../tokenHandling/selectors';

const langData = languageData();
const currentVersion = DeviceStatePackageJson.version;

const { HEATMAP } = config.frontend;

const appMenuItems = [{
    key: 'vehicleheatmap',
    route: (
        <a href={HEATMAP}>
            <FormattedMessage id='service.title.vehicleheatmap'/>
        </a>
    ),
}];

const navItems = [{
    key: 'sub_search',
    route: (
        <NavLink to={routes.DEVICE_STATE_SEARCH} className={'sub_search'}>
            <FormattedMessage id='component.search'/>
        </NavLink>
    ),
}];

export const AppContainer = (props) => {
    const {
        accessToken,
        hideSessionDialog,
        idToken,
        homeRoute,
        showSessionExpired,
    } = props;

    const { USER_SETTINGS_SERVICE } = config.backend;

    const accountMenu = (
        <DefaultAccountMenu
            accessToken={accessToken}
            idToken={idToken}
            userSettingsEndpoint={USER_SETTINGS_SERVICE}
        />
    );

    // eslint-disable-next-line jsx-a11y/anchor-has-content
    const homeLink = <a href={homeRoute}/>;

    return (
        <IntlProvider defaultLocale={DEFAULT_LANGUAGE}
            locale={langData.localeShort} messages={langData.messagesByLanguage}>
            <ApplicationLayout className={'devicestate'}>
                <ApplicationLayout.Header>
                    <ApplicationHeader
                        label={<FormattedMessage id='service.title.deviceState'/>}
                        homeRoute={homeLink}
                        navItems={navItems}
                        actionBarItems={[accountMenu]}
                        appMenuItems={appMenuItems}
                    />
                </ApplicationLayout.Header>
                <ApplicationLayout.Body>
                    <NotificationsContainer />
                    <SessionExpiredDialog
                        locale={langData.localeShort}
                        onClose={hideSessionDialog}
                        show={showSessionExpired}
                    />
                    <Switch>
                        <Route path={routes.DEVICE_STATE_SEARCH} component={SearchComponent}/>
                        <Redirect to={routes.DEVICE_STATE_SEARCH}/>
                        <Redirect to={routes.ERROR_ROUTE}/>
                    </Switch>
                </ApplicationLayout.Body>
                <ApplicationLayout.Footer className='devicestate-footer'>
                    <div className='copyright pull-left'>
                        {'© 2018, RIO - A Brand of Volkswagen Truck & Bus, All Rights Reserved'}
                    </div>
                    <div className='devicestate-version pull-right'>{`Device State v${currentVersion}`}</div>
                </ApplicationLayout.Footer>
            </ApplicationLayout>
        </IntlProvider>
    );
};

export const mapStateToProps = state => ({
    accessToken: getAccessToken(state),
    idToken: getIdToken(state),
    homeRoute: config.homeRoute,
    showSessionExpired: isUserSessionExpired(state) && !getSessionExpiredAcknowledged(state),
});

export const mapDispatchToProps = (dispatch) => ({
    hideSessionDialog: () => dispatch(hideSessionExpiredDialog()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);
