import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import { CollapsibleFilter, FilterListItem, Checkbox } from 'rio-uikit';
import { FormattedMessage } from 'react-intl';
import './DeviceFilter.scss';

const FILTER_TYPE_MILEAGE_NONE = 'none';
const ID_MILEAGE = 'mileage';

export default class DevicesFilter extends PureComponent {

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.getSelectedFilters = this.getSelectedFilters.bind(this);

        this.state = {
            selectedFilters: {
                mileage: {
                    id: ID_MILEAGE,
                    none: false,
                    onDelete: this.resetMileageFilter.bind(this),
                },
                onDeleteAll: this.deleteAllFilters.bind(this),
            },
        };
    }

    getChosenMileage(mileage) {
        const chosenOptions = [];

        if (mileage.none) {
            chosenOptions.push(<FormattedMessage id='search.filter.mileage.none'/>);
        }

        return chosenOptions;
    }

    getChosenMileageFilter(mileage) {
        const chosenOptions = [];

        if (mileage.none) {
            chosenOptions.push(FILTER_TYPE_MILEAGE_NONE);
        }

        return chosenOptions;
    }

    getSelectedFilters() {
        const { mileage } = this.state.selectedFilters;
        const chosenMileage = this.getChosenMileage(mileage);

        return [{
            title: <FormattedMessage id='search.filter.mileage'/>,
            chosenOptions: chosenMileage,
            onDelete: mileage.onDelete,
        }];
    }

    toggle(type = FILTER_TYPE_MILEAGE_NONE) {
        let newSelectedFilters;
        switch (type) {
            case FILTER_TYPE_MILEAGE_NONE:
                newSelectedFilters = Object.assign({}, this.state.selectedFilters.mileage);
                newSelectedFilters.none = !newSelectedFilters.none;
                this.setState({
                    ...this.state,
                    selectedFilters: {
                        ...this.state.selectedFilters,
                        mileage: {
                            ...this.state.selectedFilters.mileage,
                            none: newSelectedFilters.none,
                        },
                    },
                });
                break;
            default:
        }

        this.props.handleFilterOptions(this.determineChosenFilters(newSelectedFilters));
    }

    resetMileageFilter() {
        const newSelectedFilters = Object.assign({}, this.state.selectedFilters.mileage);

        // simple use-case one mileage filter; extend this logic, if more mileage filters become available
        newSelectedFilters.none = false;

        this.setState({
            ...this.state,
            selectedFilters: {
                ...this.state.selectedFilters,
                mileage: {
                    ...this.state.selectedFilters.mileage,
                    none: newSelectedFilters.none,
                },
            },
        });
        this.props.handleFilterOptions(this.determineChosenFilters(newSelectedFilters));
    }

    deleteAllFilters() {
        this.resetMileageFilter();
    }

    pushToFilters(id, values, chosenFilters) {
        if (values && values.length > 0 && id === ID_MILEAGE) {
            chosenFilters.mileage = values;
        }
    }

    determineChosenFilters(filterOptions) {
        const chosenFilters = {};
        const chosenMileage = this.getChosenMileageFilter(filterOptions);

        this.pushToFilters(filterOptions.id, chosenMileage, chosenFilters);

        return chosenFilters;
    }

    render() {
        const { mileage, onDeleteAll } = this.state.selectedFilters;

        return (
            <div>
                <div className='container-fluid'>
                    <div className='row'>
                        <CollapsibleFilter showFilters={true} //always open filters
                            selectedFilters={this.getSelectedFilters()}
                            deleteAllCaption={<FormattedMessage id='search.filter.deleteAll'/>}
                            onDeleteAll={onDeleteAll}>
                            <FilterListItem title={<FormattedMessage id='search.filter.mileage'/>}
                                gridWidth={'col-md-2'}>
                                <div>
                                    <Checkbox onClick={() => {
                                        this.toggle(FILTER_TYPE_MILEAGE_NONE);
                                    }}
                                    checked={mileage.none}
                                    label={<FormattedMessage id='search.filter.mileage.none'/>}/>
                                </div>
                            </FilterListItem>
                        </CollapsibleFilter>
                    </div>
                </div>
            </div>
        );
    }
}

DevicesFilter.defaultProps = {
    handleFilterOptions: () => {},
};

DevicesFilter.propTypes = {
    handleFilterOptions: PropTypes.func.isRequired,
};
