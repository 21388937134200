/*eslint camelcase: ["error", {properties: "never"}]*/
import _ from 'lodash';

/**
 * Renders the device data for ui.
 * @param  {[Object]} devicesData the device data to render
 * @param  {Boolean} combineVinFields indicates if the function has to merge both vins into the vin property
 * @return {[Object]}             modified copy of device data
 */
export function mapDevicesData(devicesData, combineVinFields = false) {
    return _.map(devicesData).map(device => {
        const positionData = device.builtin_position;
        const tcoInfo = device.tco_info;
        const vin = combineVinFields ? mapVin(device.vin, device.previous_vin) : device.vin;
        return {
            ...device,
            builtin_position: mapBuiltinPosition(positionData),
            builtin_date_time: (positionData) ? positionData.builtin_date_time : undefined,
            position_date_time: (positionData) ? positionData.position_date_time : undefined,
            tco_supplier: (tcoInfo) ? tcoInfo.supplier : undefined,
            tco_variant: (tcoInfo) ? tcoInfo.variant : undefined,
            tco_hardware_number: (tcoInfo) ? tcoInfo.hardware_number : undefined,
            tco_software_number: (tcoInfo) ? tcoInfo.software_number : undefined,
            vin: vin,
        };
    });
}

function mapBuiltinPosition(positionData) {
    return (positionData && positionData.latitude && positionData.longitude) ?
        `${positionData.latitude};${positionData.longitude}` :
        undefined;
}

function mapVin(currentVin, previousVin = undefined) {
    return {
        currentVin: currentVin,
        previousVin: previousVin,
    };
}

export function mapDevicesDataForCSV(devicesData, tableHeader, settings) {

    const flattenedDevices = mapDevicesData(devicesData);
    const fieldsToExport = tableHeader.map(headerElem => {
        //find the visibility setting for the corresponding header element (if exists)
        const headerElemSetting = settings.find(setting => (setting.dataField === headerElem.dataField));
        if (headerElem.hidden && (headerElemSetting && headerElemSetting.checked)) {
            return headerElem.dataField;
        }

        if (!headerElem.hidden) {
            if (headerElemSetting && !headerElemSetting.checked) {
                return null;
            }
            return headerElem.dataField;
        }
        return null;
    }).filter(e => e); //filter out null values
    // this property is not visible in the frontend but should always be present in CSV file
    if (fieldsToExport.indexOf('previous_vin') === -1) {
        const indexOfVinField = fieldsToExport.indexOf('vin');
        //we add the previous_vin field after the vin field
        fieldsToExport.splice(indexOfVinField + 1, 0, 'previous_vin');
    }

    return flattenedDevices.map(device => _.pick(device, fieldsToExport));
}
