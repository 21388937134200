/*eslint no-alert: 'off'*/
import React from 'react';
import ReactDOM from 'react-dom';
import { Route, Router, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import {
    EVENT_USER_LOGGED_OUT,
    EVENT_USER_PROFILE_CHANGED,
} from 'rio-accountmenu';
import { config } from './config';
import {
    configureMockUserManager,
    configureUserManager,
    createUserManager,
} from './login/login';
import {
    userProfileObtained,
    userSessionExpired,
    userSessionRenewed,
} from './login/actions';
import { redirectToLogout } from './login/logout';
import { accessToken } from './tokenHandling/accessToken';
import { accessTokenStored, idTokenStored } from './tokenHandling/actions';
import { history, store } from './setup/store';
import { attemptInitialSignIn } from './setup/oauth';
import { trace } from './setup/trace';
import AppContainer from './containers/AppContainer';
import { NoMatch } from './components/NoMatch';
import { routes } from './common/constants/routes';

function renderApplication() {
    const rootElement = document.getElementById('root');
    ReactDOM.render(
        <Provider store = {store}>
            <Router history = {history}>
                <Switch>
                    <Route path={routes.ERROR_ROUTE} component={NoMatch} />
                    <Route path='/' component={AppContainer} />
                    <Route component={NoMatch} />
                </Switch>
            </Router>
        </Provider>,
        rootElement
    );
}

function main(renderApp) {
    const onLogout = () => {
        accessToken.discardAccessToken();
        redirectToLogout();
    };

    const oauthConfig = {
        onSessionError: error => {
            trace('[src/index] index.onSessionError', error);
        },
        onTokenExpired: () => {
            trace('[src/index] index.onTokenExpired');

            accessToken.discardAccessToken();
            store.dispatch(userSessionExpired());
        },
        onTokenRenewed: result => {
            trace('[src/index] index.onTokenRenewed', result);

            accessToken.saveAccessToken(result.accessToken);
            store.dispatch(accessTokenStored(result.accessToken));
            store.dispatch(idTokenStored(result.idToken));
            store.dispatch(userProfileObtained(result.profile));

            store.dispatch(userSessionRenewed());
        },
    };

    const isAllowedToMockAuth = process.env.NODE_ENV !== 'production' ||
        window.location.host !== 'device-state.vcp-connector.rio.cloud';
    const userManager =
        isAllowedToMockAuth && config.login.mockAuthorization ?
            configureMockUserManager(oauthConfig) :
            configureUserManager(oauthConfig, createUserManager());

    document.addEventListener(EVENT_USER_LOGGED_OUT, onLogout);
    document.addEventListener(EVENT_USER_PROFILE_CHANGED, userManager.signinSilent.bind(userManager));

    attemptInitialSignIn(userManager)
        .then(renderApp)
        .catch(error => {
            trace('[src/index] could not start application', error);
        });
}

main(renderApplication);
