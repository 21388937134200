import { get } from '../sagas/requestUtil';
import queryString from 'query-string';
import { isBlank } from '../common/util';

export const DEVICES_PATH = '/devices';
export const REQUEST_LIMIT = 1000;

export function getAllDevices(backends, nextId, getRequest = get) {

    const url = getDevicesPageUrl(backends, nextId);

    return getRequest(url)
        .catch(err => ({error: err}));
}

function getDevicesPageUrl(backends, nextId) {

    let url = `${backends.DEVICE_STATE_SERVICE}${DEVICES_PATH}`;

    const queryParamsStr = queryString.stringify({limit: REQUEST_LIMIT, ...(nextId && {cursor: nextId})});
    if (!isBlank(queryParamsStr)) {
        url += `?${queryParamsStr}`;
    }

    return url;
}
