import React from 'react';
import { FormattedMessage } from 'react-intl';

export function isError(error) {
    return error.status === 400 || error.status === 401 || error.status === 403 || error.status === 500;
}

export function isNotFoundError(error) {
    return error.status === 404;
}

export function getResponseErrorMsg(error) {
    let msg;

    switch (error.status) {
        case 400:
            msg = <FormattedMessage id='search.error.invalid.request' />;
            break;
        case 401:
        case 403:
            msg = <FormattedMessage id='search.error.not.allowed' />;
            break;
        case 500:
            msg = <FormattedMessage id='search.error.internal.error' />;
            break;
        default:
            msg = <FormattedMessage id='search.error.undefined.error' />;
    }

    return msg;
}
