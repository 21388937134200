/*eslint no-else-return: 0*/
import { addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en';
import de from 'react-intl/locale-data/de';

addLocaleData([...en, ...de]);

const messagesDE = require('./devicestate_de-DE.json');
const messagesEN = require('./devicestate_en-US.json');

export const DEFAULT_LANGUAGE = 'en-GB';

const messages = {
    en: messagesEN,
    'en-GB': messagesEN,
    'en-US': messagesEN,

    de: messagesDE,
    'de-DE': messagesDE,
    'de-AT': messagesDE,
    'de-LI': messagesDE,
    'de-LU': messagesDE,
    'de-CH': messagesDE,
};

export const getBrowserLocale = navigator.language ;

export default function(locale, browserLocale = getBrowserLocale) {
    return setupLanguageData({}, getLocale(locale, browserLocale));
}

function getLocale(locale, browserLocale = getBrowserLocale) {
    if (messages[locale]) {
        return locale;
    } else if (messages[browserLocale]) {
        return browserLocale;
    } else {
        return DEFAULT_LANGUAGE;
    }
}

function setupLanguageData(data, loc) {
    data.messagesByLanguage = messages[loc];
    data.locale = loc;
    data.localeShort = loc.split('-')[0];

    return data;
}
