import React from 'react';
import { doShowErrorNotification } from '../sagas/notificationsSaga';
import { FormattedDate, FormattedNumber } from 'react-intl';
import { Position } from 'rio-uikit';

const serialNumberPattern = /^\d{6}-\d{4}$/;
const vinPattern = /^[A-Z0-9]{17}$/;
const coordsPattern = /^(-?\d+(\.\d+)?);(-?\d+(\.\d+)?)$/;

export function validateSerialNumber(searchString, errorMessage = '', showError = false) {
    if (!isBlank(searchString) && !searchString.match(serialNumberPattern)) {
        if (showError) {
            doShowErrorNotification(errorMessage);
        }
        return false;
    }
    return true;
}

export function isBlank(str) {
    return !str || str.length === 0 || !str.trim();
}

export function validateVin(searchString, errorMessage = '', showError = false) {
    if (!isBlank(searchString) && !searchString.match(vinPattern)) {
        if (showError) {
            doShowErrorNotification(errorMessage);
        }
        return false;
    }
    return true;
}

export function formatDate(value) {
    if (value) {
        return (
            <FormattedDate
                value={value}
                day={'numeric'}
                year={'numeric'}
                month={'numeric'}
                hour={'numeric'}
                minute={'numeric'}
                second={'numeric'}
            />
        );
    }
    return '-';
}

export function formatNumber(value) {
    if (typeof value !== 'undefined') {
        return (
            <FormattedNumber
                value={value}
                minimumFractionDigits={0}
                maximumFractionDigits={0}
            />
        );
    }
    return '-';
}

export function formatString(value) {
    if (value) {
        return value;
    }
    return '-';
}

/**
 * Formats coordinates for table. Given parameter should contain longitude and latitude data in the format
 * 'longitude;latitude'.
 *
 * @param  {any}     value  longitude/latitude as string in expected format
 * @return {String}         a {Position} link in case of valid longitude/latitude
 */
export function formatCoordinates(value) {
    if (typeof value === 'string' && value.match(coordsPattern)) {
        const coords = value.split(';');
        const lat = parseFloat(coords[0]);
        const lon = parseFloat(coords[1]);

        return (
            <a target='_blank' href={`https://share.here.com/l/${lat},${lon}`} rel='noreferrer noopener'>
                <Position vehicleState={{ latitude: lat, longitude: lon }} />
            </a>
        );
    }

    return '-';
}

export function formatVin(value) {
    const {currentVin, previousVin} = value;
    if (!currentVin && !previousVin) {
        return '-';
    }

    return (
        <div>
            {
                currentVin ?
                    <div>{currentVin} <span className='rioglyph rioglyph-plugged-on'/></div> :
                    undefined
            }
            {
                previousVin ?
                    <div><s>{previousVin}</s> <span className='rioglyph rioglyph-plugged-off'/></div> :
                    undefined
            }
        </div>
    );
}
