import cloneDeep from 'lodash/fp/cloneDeep';
import { config } from '../config';

export default function reducer(state = createInitConfigState()) {
    return state;
}

function createInitConfigState() {
    return cloneDeep(config);
}

export const getBackendServices = (state) => state.config.backend;
