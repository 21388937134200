export const SET_IS_SEARCHING = 'SET_IS_SEARCHING';

export function handleIsSearching(value) {
    return {
        type: SET_IS_SEARCHING,
        payload: {
            isSearching: value,
        },
    };
}
