/**
 * Constants for component routes.
 */

const DEVICE_STATE_SEARCH = `/devicestate/search`;
const ERROR_ROUTE = '/error';

export const routes = {
    DEVICE_STATE_SEARCH,
    ERROR_ROUTE,
};
