/*eslint camelcase: ["error", {properties: "never"}]*/
import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import { RioBootstrapTable, RioTableHeaderColumn } from 'rio-uikit';
import { FormattedMessage } from 'react-intl';
import { mapDevicesData } from '../../common/mappers';
import './DevicesTable.scss';

export default class DevicesTable extends PureComponent {
    render() {
        const { devicesData, tableHeader } = this.props;

        const tableOptions = {
            noDataText: <FormattedMessage id='search.devices.table.noData'/>,
        };

        return (
            <div id='devicesTable'>
                <RioBootstrapTable data={mapDevicesData(devicesData, true)}
                    keyField='deviceId'
                    options={tableOptions}
                    search>
                    {tableHeader.map((header, i) => {
                        return <RioTableHeaderColumn key={i}
                            dataField={header.dataField}
                            width={header.width}
                            dataSort={header.dataSort}
                            dataFormat={header.dataFormat}
                            hidden={header.default ? false : header.hidden}
                            searchable>
                            {header.text}
                            {/*this placeholder is used for rendering the content of the column*/}
                            {''}
                        </RioTableHeaderColumn>;
                    })}
                </RioBootstrapTable>
            </div>
        );
    }
}

DevicesTable.propTypes = {
    devicesData: PropTypes.arrayOf(PropTypes.shape({
        deviceId: PropTypes.string,
        box_serial_number: PropTypes.string,
        vin: PropTypes.string,
        previous_vin: PropTypes.string,
        vehicle_variant: PropTypes.string,
        software_version: PropTypes.string,
        hardware_version: PropTypes.string,
        hardware_variant: PropTypes.string,
        last_mileage: PropTypes.number,
        last_mileage_date_time: PropTypes.number,
        last_online_date_time: PropTypes.number,
        builtin_position: PropTypes.object,
        builtin_date_time: PropTypes.number,
        position_date_time: PropTypes.number,
        hardware_region: PropTypes.string,
        vehicle_type: PropTypes.string,
        gateway: PropTypes.string,
        tco_supplier: PropTypes.string,
        tco_variant: PropTypes.string,
        tco_hardware_number: PropTypes.string,
        tco_software_number: PropTypes.string,
    })),
    tableHeader: PropTypes.arrayOf(PropTypes.shape({
        dataField: PropTypes.string,
        width: PropTypes.string,
        default: PropTypes.bool,
        dataSort: PropTypes.bool,
        dataFormat: PropTypes.func,
        hidden: PropTypes.bool,
        text: PropTypes.string,
    })),
};
