import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Pager } from 'rio-uikit';
import { FormattedMessage } from 'react-intl';

export default class LoadMoreComponent extends PureComponent {
    render() {
        const { nextId, loadMoreData } = this.props;

        return (
            <div className='row'>
                <div className='col-md-12'>
                    <Pager>
                        <Pager.Item
                            className='paginationItem'
                            disabled={nextId === undefined}
                            href='#'
                            onClick={loadMoreData}
                        >
                            <FormattedMessage id='search.paging.loadMore' />
                            <span className='rioglyph rioglyph-more' />
                        </Pager.Item>
                    </Pager>
                </div>
            </div>
        );
    }
}

LoadMoreComponent.propTypes = {
    nextId: PropTypes.string,
    loadMoreData: PropTypes.func,
};
