/**
 * REST devices reducer.
 */

import {
    SUCCESSFUL_FETCH_DEVICE,
    FETCH_CSV_DEVICES_SUCCESS_ACTION,
    FETCH_CSV_DEVICES_PENDING_ACTION,
    CLEAR_CSV_DEVICES_DATA_ACTION,
    SAVE_CSV_EXPORT_SETTINGS,
} from '../../actions/devices/devicesActions';

export const defaultState = {
    response: {
        devices: [],
    },
    csvExportData: {
        pending: false,
        data: [],
        changeSet: [],
    },
};

function handleDeviceData(state, action) {
    const oldDevices = action.payload.reset ? state.response.devices.slice(state.response.devices.length) :
        state.response.devices;

    return {
        ...state,
        response: {
            ...state.response,
            devices: oldDevices.concat(action.payload.response.devices),
            nextId: action.payload.response.nextId,
        },
    };
}

function handleCSVDevicesPendingAction(state, payload) {
    const newState = {...state};

    newState.csvExportData.pending = payload.isPending;

    return newState;
}

function handleCSVDevicesSuccessAction(state, payload) {
    const newState = {...state};

    newState.csvExportData.data = payload;

    return newState;
}

function handleClearCSVDevicesDataAction(state) {
    const newState = {...state};

    newState.csvExportData.data = [];

    return newState;
}

function handleSaveCSVExportSettingsAction(state, payload) {
    const newState = {...state};

    newState.csvExportData.changeSet = payload.changeSet;

    return newState;
}

export default function restReduce(state = defaultState, action = {}) {
    switch (action.type) {
        case SUCCESSFUL_FETCH_DEVICE:
            return handleDeviceData(state, action);
        case FETCH_CSV_DEVICES_PENDING_ACTION:
            return handleCSVDevicesPendingAction(state, action.payload);
        case FETCH_CSV_DEVICES_SUCCESS_ACTION:
            return handleCSVDevicesSuccessAction(state, action.payload);
        case CLEAR_CSV_DEVICES_DATA_ACTION:
            return handleClearCSVDevicesDataAction(state);
        case SAVE_CSV_EXPORT_SETTINGS:
            return handleSaveCSVExportSettingsAction(state, action.payload);
        default:
            return state;
    }
}

export function getDevicesData(state) {
    return state.device.response.devices;
}

export function getNextId(state) {
    return state.device.response.nextId;
}

export function getCSVDevicesData(state) {
    return state.device.csvExportData.data;
}

export function getCSVDevicesPending(state) {
    return state.device.csvExportData.pending;
}

export function getCSVDevicesExportSettings(state) {
    return state.device.csvExportData.changeSet;
}
