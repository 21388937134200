import { config } from '../config';
import { history } from './store';
import { routeStorage } from '../login/storage';
import { trace } from './trace';

const param = (window, regex, defaultValue = null) => {
    let result = defaultValue;
    decodeURI(window.location.href).replace(regex, (_, it) => {
        result = it;
    });
    return result;
};

const saveCurrentRoute = () => {
    const initialRoute = [window.location.hash, window.location.search].join('').replace(/^#/u, '');

    routeStorage.saveRoute(initialRoute);

    trace('[setup/oauth] saving initial route', initialRoute);
};

export const attemptInitialSignIn = userManager => {
    const isFreshRedirect = Boolean(param(window, /access_token=([^&]+)/u));

    return userManager
        .signinSilent()
        .then(user => {
            const initialRoute = routeStorage.getRoute();

            trace('[setup/oauth] initialRoute lookup', initialRoute);
            if (initialRoute && isFreshRedirect) {
                trace(`[setup/oauth] history.replace("/${initialRoute}")`);
                history.replace(`/${initialRoute}`);
            }
            routeStorage.discardRoute();

            return Promise.resolve(user);
        })
        .catch(error => {
            trace('[setup/oauth] oidc.signinSilent failed, trying page redirect...', error);

            if (config.login.preventRedirect) {
                // eslint-disable-next-line no-console
                console.warn('[/setup/oauth] redirect prevented due to config. Error was', error);
            } else if (isFreshRedirect) {
                trace('[setup/oauth] oidc.signinSilent.error',
                    'redirect prevented due to supsicious signin error', error);
                routeStorage.discardRoute();
            } else {
                saveCurrentRoute();
                userManager.signinRedirect();
            }

            trace('[setup/oauth] auth problem?', error);
            return Promise.reject(new Error(`Need to sign in`));
        });
};
