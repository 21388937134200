import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {routes} from '../../common/constants/routes';
import {
    fetchDevice,
    handleSaveDevicesTableSettings,
    fetchCSVDevicesAction,
    clearCSVDevicesDataAction,
    saveCSVExportSettingsAction,
} from '../../actions/devices/devicesActions';
import {
    getDevicesData,
    getNextId,
    getCSVDevicesData,
    getCSVDevicesPending,
    getCSVDevicesExportSettings,
} from '../../reducers/devices/devicesRestReducer';
import {isSearching} from '../../reducers/search/searchComponentStateReducer';
import {SpinnerInfoBox} from 'rio-uikit';
import {CSVLink} from 'react-csv';
import {FormattedMessage} from 'react-intl';
import DevicesTable from '../devices/DevicesTable';
import DevicesFilter from '../devices/DevicesFilter';
import {getDevicesTableHeader} from '../../reducers/devices/devicesTableReducer';
import DevicesTableSettings from '../devices/DevicesTableSettings';
import LoadMoreComponent from './LoadMoreComponent';
import {searchIdentifier} from '../../common/constants/searchIdentifier';
import IdentifierSelectComponent from './IdentifierSelectComponent';
import {mapDevicesDataForCSV} from '../../common/mappers';

const TABLE_SETTINGS_TITLE_ID = 'search.devices.table.settings.title';
const EXPORT_SETTINGS_TITLE_ID = 'search.devices.export.settings.title';
const EXPORT_BUTTON_ID = 'search.devices.export.button';
const BUTTON_LOADING_ID = 'search.button.loading';
const SEARCH_BTN_ID = 'search.button';

const TABLE_HEADER_MODAL_ID = 'deviceTableHeaderSettings';
export const CSV_EXPORT_MODAL_ID = 'CSVExportSettings';

const PAGINATION_LIMIT = 100;
const CSV_FILE_NAME = 'devices.csv';

const identifierOptions = [
    {
        id: searchIdentifier.SEARCH_SERIAL,
        label: <FormattedMessage id='search.identifier.serialNumber'/>,
        selected: true,
    },
    {
        id: searchIdentifier.SEARCH_VIN,
        label: <FormattedMessage id='search.identifier.vin'/>,
        selected: false,
    },
];

export class SearchComponent extends PureComponent {
    constructor(props) {
        super(props);

        this.handleFilterOptions = this.handleFilterOptions.bind(this);
        this.changeIdentifierSelection = this.changeIdentifierSelection.bind(this);
        this.loadMoreData = this.loadMoreData.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleOpenTableSettings = this.handleOpenTableSettings.bind(this);
        this.handleSearchFromInput = this.handleSearchFromInput.bind(this);
        this.handleOpenCSVSettings = this.handleOpenCSVSettings.bind(this);
        this.renderSearchInputWithSelector = this.renderSearchInputWithSelector.bind(this);

        this.state = {
            identifierSelection: searchIdentifier.SEARCH_SERIAL, //serialNumber should be the initial state
            showDevicesTableSettings: false,
            showCSVExportSettings: false,
            pagination: {
                limit: PAGINATION_LIMIT,
            },
            filter: {},
        };
    }

    componentDidUpdate() {

        const {csvExportData} = this.props;
        if (csvExportData && csvExportData.length > 0) {
            this.refs.csvLink && this.refs.csvLink.link.click();
            this.props.clearCSVDevicesData();
        }
    }

    render() {
        const searchState = this.state;
        const {devicesData, devicesTableHeader, nextId} = this.props;

        return (
            <div id='searchComponent'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-md-3'>
                            <label>
                                <FormattedMessage id='search.identifier'/>
                            </label>
                        </div>
                    </div>
                    <div className='row'>
                        {this.renderSearchInputWithSelector(searchState)}
                    </div>
                    <div className='row'>
                        <div className='col-md-12'>
                            <DevicesFilter
                                handleFilterOptions={this.handleFilterOptions}/>
                        </div>
                    </div>
                    <div className='row'>
                        {this.renderSettingsButtons()}
                    </div>
                    <div className='row'>
                        <div className='col-md-12'>
                            <DevicesTable
                                devicesData={devicesData}
                                tableHeader={devicesTableHeader}/>
                        </div>
                    </div>
                    <LoadMoreComponent nextId={nextId} loadMoreData={this.loadMoreData}/>
                </div>
                {this.renderSettingsDialogs(searchState, devicesTableHeader)}
            </div>
        );
    }

    renderSettingsButtons() {

        const {csvExportData, csvDevicesPending} = this.props;
        return (
            <div className='pull-right'>
                <div className='row'>
                    <div className='col-md-6'>
                        {!csvDevicesPending ?
                            <button
                                id='openCSVSettingsButton'
                                className='btn btn-link btn-lg' onClick={this.handleOpenCSVSettings}>
                                <span className='rioglyph rioglyph-download'/>
                                <FormattedMessage id={EXPORT_BUTTON_ID}/>
                            </button> :
                            <button
                                className='btn btn-link btn-lg'>
                                <SpinnerInfoBox/>
                            </button>}
                        <CSVLink
                            ref='csvLink'
                            data={csvExportData}
                            style={{display: 'none'}}
                            separator={';'}
                            filename={CSV_FILE_NAME}
                        />
                    </div>
                    <div className='col-md-6'>
                        <button
                            id='openSettingsButton'
                            className='btn btn-link btn-lg'
                            type='button'
                            onClick={this.handleOpenTableSettings}>
                            <span className='rioglyph rioglyph-cog'/>
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    renderSearchInputWithSelector() {

        const {searching} = this.props;
        identifierOptions.map(option => {
            return option.selected = (option.id === this.state.identifierSelection);
        });

        return (
            <div>
                <div className='col-md-3'>
                    <div className='form-group'>
                        <div className='input-group'>
                            <div className='input-group-btn identifier-select'>
                                <IdentifierSelectComponent
                                    identifierOptions={identifierOptions}
                                    changeIdentifierSelection={this.changeIdentifierSelection}/>
                            </div>
                            <input
                                id='searchIdentifierInput'
                                type='text' className='form-control'
                                placeholder=''
                                disabled={searching}
                                ref={input => (this.searchIdInputRef = input)}
                                onKeyPress={this.handleSearchFromInput}/>
                        </div>
                    </div>
                </div>
                <div className='col-md-1'>
                    {!searching &&
                    <button
                        className='btn btn-primary'
                        id='searchButton' type='button'
                        onClick={() => this.handleSearch()}>
                        <FormattedMessage id={SEARCH_BTN_ID}/>
                    </button>}
                    {searching &&
                    <button className='btn btn-primary type'>
                        <SpinnerInfoBox text={<FormattedMessage id={BUTTON_LOADING_ID}/>} isInverse={true}/>
                    </button>}
                </div>
            </div>
        );
    }

    renderSettingsDialogs(searchState, devicesTableHeader) {
        return (
            <div>
                <DevicesTableSettings
                    id={TABLE_HEADER_MODAL_ID}
                    titleId={TABLE_SETTINGS_TITLE_ID}
                    isVisible={searchState.showDevicesTableSettings}
                    tableHeader={devicesTableHeader}
                    saveSettings={this.props.saveDevicesTableSettings}
                    setVisible={this.handleOpenTableSettings}/>
                <DevicesTableSettings
                    id={CSV_EXPORT_MODAL_ID}
                    titleId={EXPORT_SETTINGS_TITLE_ID}
                    isVisible={searchState.showCSVExportSettings}
                    tableHeader={devicesTableHeader}
                    saveSettings={this.props.saveCSVExportSettings}
                    setVisible={this.handleOpenCSVSettings}
                    onClickConfirm={() => this.props.requestCSVDevices()}
                />
            </div>
        );
    }

    handleFilterOptions(filterOptions) {
        this.setState({
            filter: filterOptions,
        });
    }

    handleSearchFromInput(event) {
        if (event.key === 'Enter') {
            this.handleSearch();
        }
    }

    handleOpenTableSettings(value = true) {
        this.setState(() => ({
            showDevicesTableSettings: value,
        }));
    }

    handleOpenCSVSettings(value = true) {
        this.setState(() => ({
            showCSVExportSettings: value,
        }));
    }

    loadMoreData() {
        this.handleSearch(this.props.nextId);
    }

    handleSearch(nextId) {
        const pagination = {
            ...this.state.pagination,
            nextId: nextId,
        };
        const identifierString = this.searchIdInputRef.value;
        const {identifierSelection, filter} = this.state;

        this.props.requestSearchForDevices(identifierSelection, identifierString, pagination, filter);
    }

    changeIdentifierSelection(value) {
        this.setState({
            identifierSelection: value.id,
        });
    }
}

const applicationFrameSpec = {
    name: 'sub_requesting',
    moduleKey: 'sub_requesting',
    plugins: {
        objectTree: false,
    },
    subModules: [{
        name: 'sub_devices',
        route: routes.DEVICE_STATE_SEARCH,
    }],
};

const mapStateToProps = (state) => {
    return {
        searching: isSearching(state),
        devicesData: getDevicesData(state),
        devicesTableHeader: getDevicesTableHeader(state),
        nextId: getNextId(state),
        csvExportData: mapDevicesDataForCSV(getCSVDevicesData(state),
            getDevicesTableHeader(state), getCSVDevicesExportSettings(state)),
        csvDevicesPending: getCSVDevicesPending(state),
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        requestSearchForDevices: (identifierSelection, identifierString, pagination, filter) => {
            dispatch(fetchDevice(identifierSelection, identifierString, pagination, filter));
        },
        saveDevicesTableSettings: (value) => dispatch(handleSaveDevicesTableSettings(value)),
        saveCSVExportSettings: (value) => dispatch(saveCSVExportSettingsAction(value)),
        requestCSVDevices: () => dispatch(fetchCSVDevicesAction()),
        clearCSVDevicesData: () => dispatch(clearCSVDevicesDataAction()),
    };
};

const SearchComponentContainer = connect(mapStateToProps, mapDispatchToProps)(SearchComponent);

SearchComponentContainer.spec = applicationFrameSpec;

export default SearchComponentContainer;
