import { SET_IS_SEARCHING } from '../../actions/search/searchComponentActions';

function handleSetIsSearching(state, action) {
    return {
        ...state,
        isSearching: action.payload.isSearching,
    };
}

const defaultState = {
    isSearching: false,
};

export default function searchComponentStateReduce(state = defaultState, action = {}) {
    switch (action.type) {
        case SET_IS_SEARCHING:
            return handleSetIsSearching(state, action);
        default:
            return state;
    }
}

export function isSearching(state) {
    return state.searchState.isSearching;
}
