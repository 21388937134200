import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Checkbox, ConfirmationDialog} from 'rio-uikit';
import {FormattedMessage} from 'react-intl';
import {CSV_EXPORT_MODAL_ID} from '../search/SearchComponent';

export const ABORT_BTN_ID = 'search.devices.table.settings.abort';
export const SAVE_BTN_ID = 'search.devices.table.settings.save';

export default class DevicesTableSettings extends PureComponent {
    render() {
        const changeSet = [];
        const {isVisible, tableHeader, saveSettings, setVisible, onClickConfirm, titleId, id} = this.props;

        return (
            <ConfirmationDialog
                show={isVisible}
                title={getFormattedMessage(titleId)}
                content={renderOptions(tableHeader, changeSet, id)}
                onClickConfirm={() => {
                    saveSettings(changeSet);
                    setVisible(false);
                    onClickConfirm && onClickConfirm();
                }}
                onClickCancel={() => setVisible(false)}
                cancelButtonText={getFormattedMessage(ABORT_BTN_ID)}
                confirmButtonText={getFormattedMessage(SAVE_BTN_ID)}
            />
        );
    }
}

function getFormattedMessage(id) {
    return <FormattedMessage id={id}/>;
}

function handleOptionClicked(option, changeSet) {
    option.checked = !option.checked;

    if (changeSet.length === 0) {
        changeSet.push(option);
        return;
    }

    const foundChangeSet = changeSet.find(set => (set.dataField === option.dataField));
    if (foundChangeSet !== undefined) {
        foundChangeSet.checked = option.checked;
    } else {
        changeSet.push(option);
    }
}

export function renderOptions(tableHeader, changeSet, modalId) {
    const options = [];
    const isCsvExportModal = modalId === CSV_EXPORT_MODAL_ID;

    tableHeader.map(header => {
        if (!header.default) {
            const newOption = {
                dataField: header.dataField,
                checked: !header.hidden,
                text: header.text,
            };
            options.push(newOption);
        }
        return header;
    });

    return (
        <div id='devicesTableOptions'>
            {options.map((option, i) => {
                return <p key={i}><Checkbox onClick={() => handleOptionClicked(option, changeSet)}
                    defaultChecked={option.checked}>
                    {option.text}
                </Checkbox></p>;
            })}
            {isCsvExportModal &&
                <div className='alert alert-warning' role='alert'>
                    {getFormattedMessage('search.devices.export.settings.hint')}
                </div>
            }
        </div>
    );
}

DevicesTableSettings.propTypes = {
    isVisible: PropTypes.bool,
    tableHeader: PropTypes.arrayOf(PropTypes.shape({
        dataField: PropTypes.string,
        width: PropTypes.string,
        default: PropTypes.boolean,
        dataSort: PropTypes.boolean,
        hidden: PropTypes.boolean,
        text: PropTypes.string,
    })),
    saveSettings: PropTypes.func,
    setVisible: PropTypes.func,
    onClickConfirm: PropTypes.func,
    titleId: PropTypes.string.isRequired,
};
