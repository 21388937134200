import { call, takeEvery } from 'redux-saga/effects';
import { Notification } from 'rio-uikit';
import { SHOW_ERROR_MESSAGE, SHOW_INFO_MESSAGE } from './../actions/notificationsActions';

export function doShowErrorNotification(error) {
    Notification.error(error);
}

export function doShowInfoNotification(info) {
    Notification.info(info);
}

export function* doShowErrorMessage(action) {
    const error = action.error;
    yield call(doShowErrorNotification, error);
}

export function* doShowInfoMessage(action) {
    const info = action.info;
    yield call(doShowInfoNotification, info);
}

export function* showErrorMessagesSaga() {
    yield takeEvery(SHOW_ERROR_MESSAGE, doShowErrorMessage);
}

export function* showInfoMessagesSaga() {
    yield takeEvery(SHOW_INFO_MESSAGE, doShowInfoMessage);
}
