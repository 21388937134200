import cloneDeep from 'lodash/fp/cloneDeep';
import merge from 'lodash/fp/merge';
import { accessToken } from '../tokenHandling/accessToken';

const authorizedRequest = (fetch) => (url, options) =>
    fetch(url, merge(cloneDeep(options), {
        headers: {
            Authorization: `Bearer ${accessToken.getAccessToken()}`,
            'Content-Type': 'application/json',
        },
    }));

export const get = (url, fetch = window.fetch) =>
    (authorizedRequest(fetch)(url, {method: 'GET'})
        .then(response => {
            return response.json();
        }).catch(function(exception) {
            return {
                error: exception,
            };
        }));
